<template>
    <v-container fluid>
        <v-row class="mx-3 px-3">
            <v-col cols="12">
                <h2 class="primary--text">Importações</h2>
                <v-breadcrumbs
                    large
                    :items="crumbs"
                    divider="/"
                    class="pl-0"
                ></v-breadcrumbs>
                <v-divider class="mb-12"></v-divider>
                <div style="position:relative">
                    <validation-observer ref="form" v-slot="{ invalid }">
                        <v-form @submit.prevent="submit">
                            <v-card :class="$root.$vuetify.theme.isDark ? 'secondary' : ''">
                                <v-card-text>
                                    <v-row>
                                        <v-col cols="12" md="10">
                                            <validation-provider v-slot="{ errors }" vid="Tipos" :name="'Tipos'" rules="required">
                                                <v-select
                                                    :items="tipos"
                                                    v-model="type"
                                                    label="Tipo de ficheiro"
                                                    dense outlined
                                                />
                                            </validation-provider> 
                                        </v-col>
                                        <v-col cols="12" md="2">
                                            <v-btn :disabled="type == null" class="success" @click="downloadTemplate">Download template</v-btn>
                                        </v-col>
                                        <v-col cols="12" v-if="type == 'Mapa de quantidades'">
                                            <v-autocomplete
                                                v-model="fields.obra_id"
                                                :items="obras"
                                                item-value="Id"
                                                :item-text="item => item.Codigo + ' - ' + item.Descricao"
                                                dense outlined
                                                label="Obra"
                                            />
                                        </v-col>
                                        <v-col cols="12" v-if="type == 'Mapa de quantidades'">
                                            <v-autocomplete
                                                v-model="fields.category_id"
                                                :items="categorias"
                                                item-value="id"
                                                :item-text="item => item.descricao"
                                                dense outlined
                                                label="Categoria"
                                            />
                                        </v-col>
                                        <v-col cols="12">
                                            <validation-provider v-slot="{ errors }" vid="Ficheiro" :name="'Ficheiro'" rules="required">
                                                <v-file-input
                                                    show-size
                                                    :label="'Ficheiro a importar'"
                                                    v-model="file"
                                                    :error-messages="errors"
                                                    dense outlined
                                                    accept=".csv, .xlsx"
                                                ></v-file-input>
                                            </validation-provider> 
                                        </v-col>
                                    </v-row>                           
                                    <v-row class="mt-6" align="center" justify="space-around">
                                        <v-col align="center" justify="space-around">
                                            <v-btn :disabled="invalid" depressed :class="($root.$vuetify.theme.isDark ? 'white primary--text' : 'primary') + ' mr-5 ml-5 mb-5'" type="submit">
                                                Importar
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </v-form>
                    </validation-observer>
                </div>
            </v-col>
        </v-row>
        <v-dialog v-model="importErrors.show" width="50%">
            <v-card>
                <v-card-title class="primary white--text">Erros de importação</v-card-title>
                <v-card-text class="mt-4">
                    <v-row>
                        <v-col cols="12" v-for="(item, index) in importErrors.errors" :key="index+755">
                            <span>Linha: {{item.row}} - {{item.errors[0]}}</span>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-row>
                        <v-col cols="12" justify="center" align="center">
                            <v-btn class="error" @click="importErrors.show = false">Fechar</v-btn>
                        </v-col>
                    </v-row>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <SuccessSnackbar message="Dados importados!" v-model="success"/>
        <Loader v-if="loading"/>
    </v-container>
</template>
<script>
import Import from '@/api/Import.js'
import SuccessSnackbar from "@/components/UI/Snackbars/SuccessSnackbar";
import Obra from '@/api/Obra.js'
import Loader from "@/components/UI/Loaders/Loader";
import Categorias from '@/api/Categorias.js';
import Templates from '@/api/Templates.js'

export default{
    name:"Import",
    components:{
        SuccessSnackbar,
        Loader
    },
    data(){
        return{
            file: null,
            fields: {
                obra_id: null,
                category_id:null
            },
            loading: true,
            success: false,
            type: null,
            obras: [],
            categorias:[],
            error:{
                title: '',
                message: ''
            },
            importErrors:{
                show: false,
                errors: []
            }
        }
    },
    mounted(){
        if(this.tipos.length == 1)
            this.type = this.tipos[0]


        this.fillBaseData();
    },
    methods:{
        async fillBaseData(){
            let data = {
                tipo: 'O'
            }
            
            await Obra.all(data).then((resp)   =>  {
                this.obras = resp.data;
            });
            await Categorias.all().then((resp) =>  {
                this.categorias= resp.data.sort((a,b) => a.codigo.localeCompare(b.codigo));
            });

            this.loading = false
        },
        downloadTemplate(){
            Templates.templateExporter(this.type).then((response)    =>  {
                window.open(response.data, '_blank')
            })
        },
        submit(){
            this.$refs.form.validate().then((result) => {
                this.loading = true;
                
                if(!result){
                    this.loading = false;
                    return;
                }
                
                const formData = new FormData()
                formData.append('file', this.file)
                formData.append('local', this.type)
                formData.append('obra_id', this.fields.obra_id)
                formData.append('category_id',this.fields.category_id)

                Import.import(formData).then((dt) => {
  

                    this.loading = false;


                    if(dt.data.success == false){
                        this.importErrors.errors = dt.data.errors
                        this.importErrors.show = true
                    }else 
                        this.success = true;

                    
                    }).catch(err => {
                    this.loading = false;

                        if(err.response.status == 422) {

                        this.$refs.form.setErrors(err.response.data.errors);
                        
                        return;
                        }

                        this.error.title = 'Erro: ' + err.response.status;

                        this.error.message = err.response.data.message;

                        this.loaderAction = false

                    });
            })
        }
    },
    computed: {
        tipos(){
            let returner = []

            if(this.$store.getters.hasPermission(['super', 'import.guarantees']))
                returner.push('garantias')

            if(this.$store.getters.hasPermission(['super', 'import.work_units']))
                returner.push('Unidades de Obra')

            if(this.$store.getters.hasPermission(['super', 'import.mqt']))
                returner.push('Mapa de quantidades')
           
            return returner
        },
        crumbs: function() {
        return [
            {
            text: 'Importações',
            disabled: true,
            to: '#',
            exact: true,
            },
        ];
        }
    }
}
</script>