<template>
    <section class="mt-16">
      <v-row class="mx-3 px-3">
            <v-col cols="12">
                <v-breadcrumbs
                    large
                    :items="crumbs"
                    divider="/"
                    light
                    ></v-breadcrumbs>
  
                    <h1 class="primary--text">
                    <v-icon color="primary">mdi-hard-hat</v-icon> <span>Registo de Equipa</span>
                    </h1>
  
                    <div class="mt-16">
                      <Team method="create"></Team>
                </div>
            </v-col>
        </v-row>
    </section>
  </template>
  <script>
  import Team from '@/components/Teams/Team'
  
  export default {
    components: {
        Team
    },
    data: () => ({
      
    }),
    mounted(){
  
      if(!this.$store.getters.hasPermission(["super", "teams.create"])) {
        this.$router.push('/admin');
      }
  
    },
    methods:{
    },
    computed: {
      crumbs: function() {
        return [
          {
            text: 'Config. de Obra',
            disabled: true,
            exact: true,
          },
          {
            text: 'Registo Equipa',
            disabled: true,
            to: window.location.href,
            exact: true,
          },
        ];
      }
    }
  };
  </script>
  